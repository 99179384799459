<script>
  // Using chartjs 2.9.4, latest 3.0 breaks build due to rollup issues. https://github.com/chartjs/Chart.js/issues/5179
  import chartjs from "chart.js";
  import { onMount } from "svelte";

  let title = "Paper Hands 📄 🙌";

  let coinName = "Bitcoin";

  // Price of coin from API
  let currentPrice = null;

  /*
    Array of guess objects with types:
      lockedInPrice: currentPrice // Price at time of guess //
      endPrice: null // Price when timer runs out //
      direction: direction // Up or down /
      timestamp: Date.now(), // Time at guess click //
      result: null, // Correct or Incorrect //
  */
  let guesses = [];

  let lastGuessCorrect = true;

  // TODO: Need to fix this
  // $: percentAccuracy =
  //   (guesses.map((g) => g.result).filter((r) => r === "CORRECT").length /
  //     guesses.length) *
  //   100;

  let lockedIn = false;

  // Milliseconds
  let initialTime = 1500;
  let timer = initialTime;

  let longChartPriceHistoryData = [];
  let longChartSmoothing = {
    maxValues: 10,
    data: [],
  };

  let shortChartPriceHistoryData = [];
  let guessLineData = [];

  let ctx;
  let chart;
  let longChart;
  let chartCanvas;
  let longChartCanvas;
  let chartXLength = 60;
  let intervalCount = 0;

  let coinTickers = [
    { id: 1, ticker: `BTC`, text: `Bitcoin` },
    { id: 2, ticker: `FUN`, text: `FunCoin` },
  ];

  let activeCoin = coinTickers[1];
  let activeCoinTicker = activeCoin.ticker;

  let numXLabels = 4;
  let xLabelDistance = chartXLength / numXLabels;
  let labels = [];
  for (let i = -1 * chartXLength; i <= 0; i++) {
    if (i % xLabelDistance == 0) {
      labels.push(i + "s");
    } else {
      labels.push("");
    }
  }

  let longChartXLength = 1000;
  let longLabels = [];
  for (let i = -1 * longChartXLength; i <= 0; i++) {
    longLabels.push(i);
  }

  let longChartYMin = Number.MAX_SAFE_INTEGER;
  let longChartYMax = Number.MIN_SAFE_INTEGER;

  //Options: https://www.chartjs.org/docs/latest/charts/line.html
  let shortChartConfig = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          label: "Coin Price",
          fill: true,
          lineTension: 0.2,
          borderColor: "rgb(52, 189, 235)",
          backgroundColor: "rgba(52, 189, 235, .3)",
          borderWidth: 3,
          radius: 0,
          data: shortChartPriceHistoryData,
        },
        {
          label: "Guess",
          fill: false,
          lineTension: 0.0,
          borderColor: "white",
          backgroundColor: "white",
          borderWidth: 2,
          radius: 0,
          data: guessLineData,
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },
      responsive: true,
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
  };

  let longChartConfig = {
    type: "line",
    data: {
      labels: longLabels,
      datasets: [
        {
          label: "Coin Price",
          fill: true,
          lineTension: 0.1,
          borderColor: "rgba(52, 189, 235, .5)",
          backgroundColor: "rgba(52, 189, 235, .2)",
          borderWidth: 0.5,
          radius: 0,
          data: longChartPriceHistoryData,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            display: false,
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              // min: longChartYMin,
              // max: longChartYMax,
              fontColor: "white",
            },
            scaleLabel: {
              fontColor: "white",
            },
          },
        ],
        xAxes: [
          {
            display: false,
          },
        ],
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
    },
  };

  onMount(async () => {
    ctx = chartCanvas.getContext("2d");
    chart = new chartjs(ctx, shortChartConfig);
    longChart = new chartjs(longChartCanvas, longChartConfig);
    populateHistoricChart(activeCoinTicker);
  });

  function setLockInPrice(direction) {
    new Audio("/220212__gameaudio__ping-bing.wav").play();
    lockedIn = true;
    guesses.push({
      lockedInPrice: currentPrice,
      endPrice: null,
      direction: direction,
      timestamp: Date.now(),
      result: null,
      guessData: Array(shortChartPriceHistoryData.length - 1).fill(null),
      guessDataIntervalAge: 1,
    });

    // https://svelte.dev/tutorial/updating-arrays-and-objects
    guesses = guesses;

    let guess = guesses[guesses.length - 1];

    chart.data.datasets.push({
      label: "Locked In",
      fill: true,
      lineTension: 0.15,
      borderColor: guess.borderColor,
      backgroundColor: guess.backgroundColor,
      borderWidth: 3,
      radius: 0,
      data: guess.guessData,
    });

    guess.guessData.push(
      shortChartPriceHistoryData[shortChartPriceHistoryData.length - 1]
    );

    let numNullsToAppendGuessLine =
      shortChartPriceHistoryData.length - guessLineData.length;
    guessLineData = guessLineData.concat(
      Array(numNullsToAppendGuessLine).fill(null)
    );

    const countdownTimer = setInterval(function () {
      timer -= 10;
      if (timer == 0) {
        clearInterval(countdownTimer);
        guess.endPrice = currentPrice;
        lockedIn = false;
        timer = initialTime;

        if (
          (direction === "UP" && guess.endPrice > guess.lockedInPrice) ||
          (direction === "DOWN" && guess.endPrice < guess.lockedInPrice)
        ) {
          guess.result = "CORRECT";
          new Audio("/220173__gameaudio__spacey-1up-power-up.wav").play();
        } else {
          guess.result = "INCORRECT";
          new Audio("/220174__gameaudio__spacey-loose.wav").play();
        }
      }
      guesses = guesses;
    }, 100);
  }

  const getHistoricTrades = async (coinTicker, length) => {
    const response = await fetch(
      "https://vrxmr8rt05.execute-api.us-west-1.amazonaws.com/p/historicalprice?coin=" +
        coinTicker +
        "&depth=" +
        length
    );
    const myJson = await response.json();
    return myJson;
  };

  let lastIntervalTimeMS = new Date().getTime();
  setInterval(async function () {
    let currentTimeMS = new Date().getTime();

    // if the chart hasn't updated within 1.5 seconds, refresh the charts.
    if (currentTimeMS - lastIntervalTimeMS > 1500) {
      console.log("Refreshing charts after inactivity.");
      populateChart();
      populateHistoricChart(activeCoinTicker);
    }

    intervalCount += 1;
    // TODO: Replace with Crypto API call
    // currentPrice = getPriceFake();
    let apiResponse = await getPriceReal(activeCoinTicker);
    console.log(apiResponse);
    currentPrice = parseFloat(apiResponse.price);
    if (lockedIn) {
      if (guessLineData[guessLineData.length - 1] == null) {
        guessLineData[guessLineData.length - 1] =
          guesses[guesses.length - 1].lockedInPrice;
      }
      guessLineData.push(guesses[guesses.length - 1].lockedInPrice);
    } else {
      guessLineData.push(null);
    }
    if (guessLineData.length > chartXLength + 1) {
      guessLineData.shift();
    }

    chart.data.datasets[1].data = guessLineData;

    if (currentPrice) {
      addPointToPriceGraph(currentPrice);

      addPointToLongGraph(currentPrice);
      longChartPriceHistoryData.shift();

      chart.stop();
      // chart.reset();
      chart.update();
      longChart.update();
    }
    lastIntervalTimeMS = new Date().getTime();
  }, 1000);

  populateChart();

  function addPointToPriceGraph(price) {
    let currentGuess = guesses[guesses.length - 1];

    if (lockedIn) {
      currentGuess.guessData.push(price);
      shortChartPriceHistoryData.push(null);
      if (
        (currentGuess.direction === "UP" &&
          price > currentGuess.lockedInPrice) ||
        (currentGuess.direction === "DOWN" &&
          price < currentGuess.lockedInPrice)
      ) {
        lastGuessCorrect = true;
        chart.data.datasets[chart.data.datasets.length - 1].borderColor =
          "rgb(52, 235, 52)";
        chart.data.datasets[chart.data.datasets.length - 1].backgroundColor =
          "rgba(52, 235, 52, .3)";
      } else {
        lastGuessCorrect = false;
        chart.data.datasets[chart.data.datasets.length - 1].borderColor =
          "rgb(235, 52, 52)";
        chart.data.datasets[chart.data.datasets.length - 1].backgroundColor =
          "rgba(235, 52, 52, .3)";
      }
    } else {
      if (
        shortChartPriceHistoryData[shortChartPriceHistoryData.length - 1] ===
        null
      ) {
        shortChartPriceHistoryData[shortChartPriceHistoryData.length - 1] =
          currentGuess.guessData[currentGuess.guessData.length - 1];
      }
      shortChartPriceHistoryData.push(price);
    }

    for (let i = 0; i < guesses.length; i++) {
      let guess = guesses[i];
      if (guess.guessData.length > chartXLength) {
        guess.guessData.shift();
      }
      if ((lockedIn && guess != currentGuess) || !lockedIn) {
        guess.guessData.push(null);
      }
    }
    if (shortChartPriceHistoryData.length > chartXLength + 1) {
      shortChartPriceHistoryData.shift();
    }
  }

  function addPointToLongGraph(price) {
    longChartSmoothing.data.push(price);
    if (longChartSmoothing.data.length > longChartSmoothing.maxValues) {
      longChartSmoothing.data.shift();
    }

    let averagePrice = 0;
    for (let i = 0; i < longChartSmoothing.data.length; i++) {
      averagePrice += longChartSmoothing.data[i];
    }

    averagePrice = averagePrice / longChartSmoothing.data.length;

    longChartPriceHistoryData.push(averagePrice);
  }

  function resetCharts() {
    chart.stop();
    populateChart();
    longChartPriceHistoryData.splice(0, longChartPriceHistoryData.length);
    // currentPrice = null;
    longChart.stop();
    populateHistoricChart();
  }

  function getPriceFake() {
    return (currentPrice += Math.random() * 2 - 1);
  }

  const getPriceReal = async (coinTicker) => {
    const response = await fetch(
      "https://vrxmr8rt05.execute-api.us-west-1.amazonaws.com/p/currentprice?coin=" +
        coinTicker
    );
    const myJson = await response.json();
    return myJson;
  }; //extract JSON from the http response console.log(myJson); // do something with myJson };

  async function populateChart() {
    shortChartPriceHistoryData.length = 0;
    let apiResponse = await getHistoricTrades(activeCoinTicker, chartXLength);
    let ticks = apiResponse.historicalPrices;
    for (let i = ticks.length - 1; i >= 0; i--) {
      let trade = parseFloat(ticks[i]);
      addPointToPriceGraph(trade);
    }
    chart.update();
    // chart.options.animation.duration = 300;
  }

  async function populateHistoricChart() {
    longChartPriceHistoryData.length = 0;
    let apiResponse = await getHistoricTrades(
      activeCoinTicker,
      longChartXLength
    );

    let ticks = apiResponse.historicalPrices;

    for (let i = ticks.length - 1; i >= 0; i--) {
      let trade = parseFloat(ticks[i]);
      addPointToLongGraph(trade);
    }
  }

  function convertTimeStampToString(timestamp) {
    let d = new Date(timestamp);
    return d.toLocaleString();
  }

  function getCurrentStreak() {
    let count = 0;
    // https://stackoverflow.com/questions/30610523/reverse-array-in-javascript-without-mutating-original-array
    for (let guess of guesses.slice().reverse()) {
      // Make sure the result exists
      if (guess.result === "CORRECT") {
        count++;
      } else if (guess.result === null) {
        continue;
      } else {
        break;
      }
    }
    return count;
  }
</script>

<h1>{title}</h1>

<div class="content">
  <main class="main">
    <span class="predictmessage"> 🔮 Can you predict the price of </span>
    <span class="predictmessage"
      ><select
        class="select"
        bind:value={activeCoin}
        on:change={() => (activeCoinTicker = activeCoin.ticker)}
        on:change={() => resetCharts()}>
        {#each coinTickers as ticker}
          <option value={ticker}>
            {ticker.text}
          </option>
        {/each}
      </select></span
    ><span class="predictmessage">
      in the next {(timer / 100).toFixed(2)} seconds?
    </span>

    <br />
    <canvas bind:this={chartCanvas} />

    <div>
      <div class="prices">
        <div class="card currentprice">
          💲 Current Price<br />
          <p class="values">
            {currentPrice ? currentPrice.toFixed(4) : "-"}
          </p>
        </div>
        <div class="card lastsetprice">
          🔒 Set Price <br />
          {#if guesses[guesses.length - 1]?.lockedInPrice}
            <p class="values animate__animated animate__flipInX animate_faster">
              {guesses[guesses.length - 1].lockedInPrice.toFixed(4)}
            </p>
            {#if guesses[guesses.length - 1]?.direction === "UP"}
              <p class="values lastguess animate__animated animate__zoomIn">
                🚀
              </p>
            {:else if guesses[guesses.length - 1]?.direction === "DOWN"}
              <p class="values lastguess animate__animated animate__zoomIn">
                📉
              </p>
            {/if}
          {:else}
            <p class="values">--.---</p>
          {/if}
        </div>
        <div class="card lastpricechange">
          💸 Price Change <br />
          {#if guesses[guesses.length - 1]?.lockedInPrice && guesses[guesses.length - 1]?.endPrice}
            <p class="values">
              {(
                guesses[guesses.length - 1].endPrice -
                guesses[guesses.length - 1].lockedInPrice
              ).toFixed(4)}
            </p>
          {:else if guesses[guesses.length - 1]?.lockedInPrice}
            <p class="values animate__animated animate__flipInX animate_faster">
              {(
                currentPrice - guesses[guesses.length - 1].lockedInPrice
              ).toFixed(4)}
            </p>
          {:else}
            <p class="values ">-</p>
          {/if}
        </div>
        <div class="card lastendprice">
          💰 End Price<br />
          {#if guesses[guesses.length - 1]?.endPrice}
            <p class="values animate__animated animate__rubberBand positive">
              {guesses[guesses.length - 1].endPrice.toFixed(4)}
            </p>
          {:else}
            <p class="values">--.---</p>
          {/if}
        </div>
      </div>
      <br />
      <div class="buttons">
        <button
          class="glow-on-hover"
          on:click={() => setLockInPrice("UP")}
          disabled={lockedIn || !currentPrice}>UP🚀</button>
        <button
          on:click={() => setLockInPrice("DOWN")}
          disabled={lockedIn || !currentPrice}>DOWN 📉</button>
      </div>
    </div>

    <br />
  </main>

  <div class="sidebar">
    <div class="progress">
      <div
        class={timer > 300 ? "progress-value" : "progress-value-low"}
        style="width: {(timer / initialTime) * 100}%" />
    </div>
    <div class="card col8">
      <!-- TODO: THIS IS NOT CALCULATED CORRECTLY ANYMORE -->
      <!-- {isNaN(percentAccuracy) ? 0 : percentAccuracy.toFixed(2)}% Accuracy -->
      <p class="streakheader">🔥 Current Streak</p>
      {#if guesses.length > 0 && guesses[guesses.length - 1]?.endPrice}
        <p class="values streaknum animate__animated animate__jackInTheBox">
          {getCurrentStreak()}
        </p>
      {:else if guesses.length > 0 && !guesses[guesses.length - 1]?.endPrice}
        <p class="values streaknum">{getCurrentStreak()}</p>
      {:else}
        <p class="values streaknum">-</p>
      {/if}
      <div class="streak-icons">
        {#each guesses as guess}
          {#if guess.result === "CORRECT"}
            <div class="streak-icon animate__animated animate__bounce">✔️</div>
          {/if}
          {#if guess.result === "INCORRECT"}
            <div class="streak-icon animate__animated animate__wobble">❌</div>
          {/if}
        {/each}
      </div>
    </div>
    <div class="card">
      <details>
        <summary>
          <canvas bind:this={longChartCanvas} class="test" />
          View History...</summary>
        <table>
          <thead>
            <th>Guess Time</th>
            <th>Price at Guess</th>
            <th>Direction</th>
            <th>End Price</th>
            <th>Result</th>
          </thead>
          {#each [...guesses].reverse() as guess}
            <tr>
              <td>{convertTimeStampToString(guess.timestamp)}</td>
              <td>{guess.lockedInPrice.toFixed(3)}</td>
              <td>{guess.direction}</td>
              <td>{guess.endPrice ? guess.endPrice.toFixed(3) : "-"}</td>
              <td>{guess.result || "-"}</td>
            </tr>
          {/each}
        </table>
      </details>
    </div>
  </div>
  <br />
  <br />
</div>
<img
  id="elon"
  src={lastGuessCorrect ? "/elonhappy.png" : "/elonsad.png"}
  alt="Elon" />

<style>
  :global(html) {
    /* https://dogemuchwow.com/10-cool-doge-wallpapers-in-hd/ */
    background: url("/dogebg.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  :global(body) {
    width: 1100px;
    margin: auto;
    color: #fff;
  }

  .content {
    display: flex;
  }

  .main {
    flex-basis: 65%;
    margin-right: 30px;
  }

  .sidebar {
    flex-basis: 40%;
  }

  details,
  summary {
    padding: 5px;
    border-radius: 5px;
    text-align: center;
  }

  details:hover {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 10px;
    width: 100%;
    table-layout: fixed;
  }

  #elon {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 20%;
  }
  main {
    padding: 0.15em;
    margin: 0 auto;
  }

  summary {
    position: relative;
    height: 80px;
  }

  .test {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100%;
  }

  h1 {
    text-transform: uppercase;
    font-size: 1.4em;
    font-weight: 400;
    margin-left: 5px;
    font-family: "Fredoka One", cursive;
  }

  p {
    margin: 0;
  }

  .prices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .card {
    background-color: #202b38;
    padding: 5px;
    padding-left: 10px;
    margin: 5px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  /* Inspired by https://watercss.kognise.dev/ */
  button {
    font-size: 24px;
    margin: 5px;
    font-weight: 600;
    background-color: #161f27;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    transition: opacity 0.1s linear, background-color 0.1s linear,
      border-color 0.1s linear, color 0.1s linear, box-shadow 0.1s linear,
      transform 0.1s ease;
    border-radius: 7px;
    box-shadow: 0px 6px 0px 0px #202b38;
  }
  button:hover:enabled {
    background-color: #324759;
    cursor: pointer;
    transform: scale(1.05);
  }

  button:active {
    box-shadow: none;
    transform: translateY(5px);
  }
  button:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  .values {
    font-size: 24px;
    color: #daf6ff;
    text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
  }

  .streakheader {
    text-align: center;
  }

  .streak-icons {
    display: flex;
  }

  .streaknum {
    font-size: 40px;
    text-align: center;
  }

  .predictmessage {
    padding: 5px;
    border-radius: 3px;
    line-height: 2;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .streak-icon {
    font-size: 16px;
    padding: 5px;
    border-radius: 3px;
    background-color: #202b38;
    margin-right: 5px;
  }

  .lastsetprice {
    position: relative;
  }

  .lastguess {
    position: absolute;
    right: 0;
    top: 0;
  }

  .progress {
    background: #202b38;
    justify-content: flex-center;
    border-radius: 100px;
    align-items: center;
    display: flex;
    height: 10px;
    width: 97%;
    margin: auto;
  }

  .progress-value {
    transition: 0.0001s all ease-in;
    box-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
    border-radius: 100px;
    background: #fff;
    height: 5px;
    width: 0%;
  }

  .progress-value-low {
    transition: 0.0001s all ease-in;
    box-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
    border-radius: 100px;
    background: red;
    height: 5px;
    width: 0%;
  }

  .select {
    background-color: #202b38;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  @keyframes load {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @media (min-width: 1000px) and (max-width: 1300px) {
    :global(body) {
      width: 90%;
    }
    #elon {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    :global(body) {
      width: 90%;
    }
    #elon {
      display: none;
    }
  }
  @media (max-width: 768px) {
    :global(body) {
      background-position: center top !important;
      width: 100%;
    }

    .main {
      margin: 0px;
    }
    .content {
      flex-direction: column;
    }
    h1 {
      font-size: 1.5em;
    }
    .values {
      font-size: 24px;
    }

    .prices {
      grid-template-columns: repeat(3, 1fr);
    }

    .currentprice {
      grid-column: 1 / -1;
    }
    #elon {
      display: none;
    }

    .buttons {
      display: flex;
    }

    .buttons > button {
      flex-grow: 1;
    }

    .longChart {
      display: none;
    }
  }
</style>
